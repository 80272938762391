import React, { Fragment } from "react"
import Menu from "../components/menu"
import { graphql } from "gatsby"

const Droplet = require("../images/droplet.svg")

const DrinksPage = ({ data }) => {
  const pages = data.allWpPage.edges
  const id = "cG9zdDozNjQ="
  var drinks_page = {}

  for (var i = 0; i < pages.length; i++) {
    if (pages[i].node.id === id) {
      drinks_page = pages[i].node
    }
  }

  const drinks_menu = drinks_page.drinks_menu

  const setClassActive = "drinks"

  if (drinks_page) {
    return (
      <div className="main_wrapper">
        <Menu setClassActive={setClassActive}></Menu>
        <div className="drinks_menu">
          <div className="drinks_menu_title">Drinks menu</div>
          <div className="drinks_menu_list">
            <div className="drinks_wrapper">
              {drinks_menu ? (
                <>
                  {drinks_menu.section.map((section, i) => (
                    <div className="row" key={i}>
                      <div className="col col-12">
                        <h2 className="drinks_category">
                          {section.sectionTitle}
                        </h2>
                      </div>
                      {section.sectionItems.map((item, j) => (
                        <Fragment key={j}>
                          <div className="col col-11">
                            <h3 className="body--l bold">{item.itemTitle}</h3>
                            <p className="body--m">{item.itemDescription}</p>
                          </div>
                          <div className="col col-1 drinks_item bold">
                            <span className="body--l">£{item.itemPrice}</span>
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  ))}
                </>
              ) : (
                <div>Not</div>
              )}
              <div className="droplet">
                <img src={Droplet} alt="the cove" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return <div></div>
}

export default DrinksPage

export const query = graphql`
  query {
    allWpPage {
      edges {
        node {
          id
          slug
          drinks_menu {
            section {
              sectionTitle
              sectionItems {
                itemTitle
                itemDescription
                itemPrice
              }
            }
          }
        }
      }
    }
  }
`
